<template>
  <div style="min-width:1290px;">
    <MyBanner />
    <div>
      <div class="header-img">
        <div class="title-img">
          <div style="font-size: 42px;font-weight: 500;color: rgba(0, 82, 217, 1);position: relative;">
            <div>智能投保平台</div>
            <div style="width: 380px;position: absolute;bottom: -40px;left: -60px;"><img src="./../static/zntb.png" alt=""></div>
          </div>
          <div style="font-size: 24px;font-weight: 400;color: rgba(102, 102, 102, 1);margin-top:10px;">全流程在线办理 极速出函</div>
        </div>
        <div class="title-tip w1300">
          <div style="display: flex;justify-content: space-between;">
            <div v-for="(item,index) in titleArr" :key="item.id" style="display: flex;flex-direction: column;align-items: center;">
              <div style="width: 75px;height: 52px;"><img :src="item.url" alt="" style="object-fit:none;margin-left: 5px;"></div>
              <div style="display: flex;align-items: center;padding-top:15px;">
                <div style="width: 82px;height: 0px;border: 1px dashed rgb(42, 123, 234);" :style="index>2?'border: 1px dashed rgb(166,166,166);':''"></div>
                <div style="width: 16px;height: 16px;background: rgb(42, 123, 234);border-radius:50%;" :style="index>2?'background: rgb(215, 215, 215);':''"></div>
                <div style="width: 82px;height: 0px;border: 1px dashed rgb(166,166,166);" :style="index<2?'border: 1px dashed rgb(42, 123, 234);':''"></div>
              </div>
              <div style="padding-top:15px;font-size: 18px;color: rgb(69, 87, 124);" :style="index>2?'color: rgb(215, 215, 215);':''">{{item.title}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="SignUpForms">
        <div class="SignUpForms-box w1300">
          <div class="SignUpForms-box-title">请选择机构</div>
          <div class="SignUpForms-box-content">
            <div v-if="UnderwritingList==''" style="width: 100%;text-align:center;">暂无数据</div>
            <div v-else class="SignUpForms-box-content-item" @click.stop="ApplicationInsurance(UnderwritingList.id,UnderwritingList.fullName,UnderwritingList.commitmentLetter)">
              <div class=" border">
                <div class="p24">
                  <div style="font-size: 20px;color: #fff;">{{UnderwritingList.deptName}}</div>
                  <div style="display: flex;">
                    <div class="box-bgc" @click.stop="guideline(UnderwritingList.guideline)">准入准则</div>
                    <div class="box-bgc" @click.stop="notice(UnderwritingList.notice)">投保须知</div>
                    <div class="box-bgc" @click.stop="clause(UnderwritingList.clause)">保函条款</div>
                  </div>
                </div>
                <div class="img">
                  <img :src="getDynamicImageUrl(UnderwritingList.logoUrl)" alt="" style="width: 50px;height:50px;border-radius: 50%;">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MyFooter />
    <el-dialog v-model="dialogVisible1" title="准入准则" width="50%">
      <div v-html="guidelineList"></div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible1 = false" size="large">确定</el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogVisible2" title="投保须知" width="50%">
      <div v-html="noticeList"></div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible2 = false" size="large">确定</el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogVisible3" title="保函条款" width="50%">
      <div v-html="clauseList"></div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible3 = false" size="large">确定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import MyBanner from "@/components/MyBanner.vue";
import MyFooter from "@/components/MyFooter.vue";
import { useRouter } from "vue-router";
import { ref, onMounted } from "vue";
import { searchUnderwriting } from "@/api/index.js"
let dialogVisible1 = ref(false)
let dialogVisible2 = ref(false)
let dialogVisible3 = ref(false)
let router = useRouter();
let guidelineList = ref()
let noticeList = ref()
let clauseList = ref()
let titleArr = ref([
  { id: 1, title: "项目申请", url: require('./../static/ico-01.png') },
  { id: 2, title: "项目审核", url: require('./../static/step2.png') },
  { id: 3, title: "保证申请", url: require('./../static/step3.png') },
  { id: 4, title: "机构审核", url: require('./../static/ico-02.png') },
  { id: 5, title: "费用缴纳", url: require('./../static/ico-04.png') },
  { id: 6, title: "出函", url: require('./../static/ico-05.png') },
  { id: 6, title: "人社审核", url: require('./../static/ico-02.png') },
])
let regionValue = ref()
let UnderwritingList = ref({})
let ApplicationInsurance = (id, name, url) => {
  router.push({ path: "/applicationInsurance", query: { ids: id, projectId: router.currentRoute.value.query.ids, UnderwritingName: name, commitmentLetter: url, nature: router.currentRoute.value.query.nature, relationId: router.currentRoute.value.query.relationId, notice: regionValue.value } });
}
let Underwriting = async () => {
  await searchUnderwriting({ id: regionValue.value }).then(res => {
    UnderwritingList.value = res.data
  })
}
let getDynamicImageUrl = (url) => {
  let currentUrl = window.location.href;
  let parts = currentUrl.split('/');
  let baseUrl = `${parts[0]}//${parts[2]}`;
  return `${baseUrl}` + '/' + `${url}`;
}
let guideline = (value) => {
  guidelineList.value = value
  dialogVisible1.value = true
}
let notice = (value) => {
  noticeList.value = value
  dialogVisible2.value = true
}
let clause = (value) => {
  clauseList.value = value
  dialogVisible3.value = true
}
onMounted(async () => {
  regionValue.value = router.currentRoute.value.query.region
  await Underwriting()
})
</script>
<style scoped>
.img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  object-fit: cover;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.p24 {
  height: 100%;
  padding: 25px;
  padding-bottom: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.box-bgc {
  font-size: 12px;
  color: #fff;
  border-radius: 6px;
  background: #8ebbfd;
  border: 1px solid #8ebbfd;
  box-shadow: 0px 1px 2px rgba(60, 64, 67, 0.3),
    0px 1px 3px 1px rgba(60, 64, 67, 0.15);
  padding: 3px 8px;
  margin-left: 10px;
  z-index: 999;
}
.box-bgc:nth-child(1) {
  margin-left: 0;
}
.SignUpForms-box-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.SignUpForms-box-content-item {
  width: 380px;
  height: 160px;
  border-radius: 6px;
  background: linear-gradient(
    135deg,
    rgba(31, 112, 247, 1) 0%,
    rgba(85, 155, 254, 1) 100%
  );
  box-shadow: 2px 2px 6px 2px rgba(60, 64, 67, 0.2);
  padding: 15px;
  box-sizing: border-box;
  cursor: pointer;
}
.border {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  position: relative;
}
.SignUpForms-box-title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0 30px 0;
  box-sizing: border-box;
  font-size: 20px;
  font-weight: 700;
  color: rgba(0, 0, 0, 1);
}
.btn {
  border-radius: 4px;
  background: linear-gradient(
    135deg,
    rgba(31, 112, 247, 1) 0%,
    rgba(85, 155, 254, 1) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 16px 7px 16px;
  box-sizing: border-box;
  color: #fff;
  margin-top: 30px;
  cursor: pointer;
}
.SignUpForms {
  width: 100%;
  background: rgb(246, 247, 250);
}
.SignUpForms-box {
  padding-top: 90px;
  box-sizing: border-box;
}
img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.header-img {
  width: 100%;
  height: 340px;
  background: url("./../static/banner-pub.png") no-repeat center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  position: relative;
}
.title-img {
  width: 360px;
  height: 119px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title-tip {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -75px;
  border-radius: 6px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 0px rgba(17, 17, 26, 0.05),
    0px 0px 8px 2px rgba(0, 82, 217, 0.1);
  padding: 20px 0;
  box-sizing: border-box;
}
.w1300 {
  width: 1300px;
  margin: 0 auto;
}
</style>
